import React, { useEffect, useState } from 'react';
import { Builder, BuilderComponent } from '@builder.io/react';

import Layout from '../components/organisms/Layout/Layout';
import SEO from '../components/organisms/Seo/Seo';

import styles from './404.module.css';

const PageNotFound = () => {
  const [display404, setDisplay404] = useState(false);

  const isProductUrl = (typeof window !== 'undefined' && window.location.href.indexOf('/product/') > -1) || false;

  useEffect(() => {
    if (isProductUrl) {
      window.location = '/shop/?nf=true';
    } else {
      if (!display404) {
        setDisplay404(true);
      }
    }
  }, [display404, isProductUrl]);

  if (Builder.isPreviewing || Builder.isEditing) {
    return <BuilderComponent model="page" />;
  }

  if (display404) {
    return (
      <Layout>
        <SEO title="Page Not Found" />
        <div className={styles.layout}>
          <div className={styles.container}>
            <h1 className={styles.title}>Oops!</h1>
            <p className={styles.description}>
              We can’t find the product or page you are trying to access.
              {isProductUrl && (
                <>
                  <br />
                  Please wait while we redirect you to the catalog...
                </>
              )}
            </p>
            <span className={styles.errorCode}>Error code: 404</span>
          </div>
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

export default PageNotFound;
